import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { Container, Nav, NavItem, Navbar } from 'reactstrap';
import StrategySelector from './components/StrategySelector';
import PortfolioView from './components/PortfolioView';
import PriceChart from './components/PriceChart';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar color="dark" dark expand="md" className="mb-4">
          <Container>
            <Nav navbar>
              <NavItem>
                <NavLink to="/" className={({ isActive }) => 
                  "nav-link" + (isActive ? " active" : "")
                }>
                  Strategy Selector
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/portfolio" className={({ isActive }) => 
                  "nav-link" + (isActive ? " active" : "")
                }>
                  Portfolio
                </NavLink>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        <Container className="mt-4">
          <Routes>
            <Route path="/" element={<StrategySelector />} />
            <Route path="/portfolio" element={<PortfolioView />} />
          </Routes>

          <PriceChart />
        </Container>
      </div>
    </Router>
  );
}

export default App;
const API_BASE_URL = '/tdapi';
//const API_BASE_URL = 'http://127.0.0.1:5000/tdapi';

export async function getStrategy(symbol, userIntuition, nearExpiryDate, currentDate, currentTime) {
  const response = await fetch(`${API_BASE_URL}/get_strategy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ symbol, userIntuition, nearExpiryDate, currentDate, currentTime }),
  });
  return response.json();
}

export const getAllStrategiesData = async ({ symbol, expiryDate, lastPrice, volatility, trend }) => {
  const response = await fetch(`${API_BASE_URL}/get_all_strategies_data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ symbol, expiryDate, lastPrice, volatility, trend }),
  });
  
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  
  return response.json();
};


export async function placeOrder(data) {
  const response = await fetch(`${API_BASE_URL}/place_order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function getPnL(symbol) {
  const response = await fetch(`${API_BASE_URL}/get_pnl?symbol=${symbol}`);
  return response.json();
}

export async function exitStrategy(data) {
  const response = await fetch(`${API_BASE_URL}/exit_strategy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
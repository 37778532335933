import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardBody, CardHeader } from 'reactstrap';

function PriceChart() {
  const [priceData, setPriceData] = useState([]);
  const ws = useRef(null);

  useEffect(() => {
    // Connect to WebSocket
    /*
    ws.current = new WebSocket('wss://your-websocket-url');

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setPriceData((prevData) => [...prevData, data]);
    };

    return () => {
      ws.current.close();
    };
    */
  }, []);

  const chartData = {
    labels: priceData.map((_, index) => index),
    datasets: [
      {
        label: 'Price',
        data: priceData.map((d) => d.price),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  return (
    <Card className="mt-4">
      <CardHeader>Real-time Price Chart</CardHeader>
      <CardBody>
        {/*<Line data={chartData} />*/}
      </CardBody>
    </Card>
  );
}

export default PriceChart;